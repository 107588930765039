import { initializeApp } from 'firebase/app';
import {
  initializeAppCheck as initializeFirebaseAppCheck,
  ReCaptchaV3Provider,
} from 'firebase/app-check';
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getFirestore,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

let appCheck = null;

export const initializeAppCheck = () => {
  if (appCheck) return appCheck;

  if (import.meta.env.VITE_NODE_ENV === 'development') {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

  try {
    appCheck = initializeFirebaseAppCheck(app, {
      provider: new ReCaptchaV3Provider(
        import.meta.env.VITE_FIREBASE_APP_CHECK_KEY
      ),
      isTokenAutoRefreshEnabled: true,
      debug: import.meta.env.VITE_NODE_ENV === 'development',
      //fallbackProvider: 'debug',
    });
  } catch (error) {
    console.warn('AppCheck initialization failed:', error);
    if (!import.meta.env.VITE_NODE_ENV === 'development') {
      throw error;
    }
  }

  return appCheck;
};

/**
 * Get classes by type
 * @param {string} type - The type of the class
 * @returns {array} - The classes
 */
export async function getClassesByType(type) {
  try {
    const q = query(
      collection(db, 'classes'),
      where('type', '==', type),
      orderBy('class')
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error al obtener el listado de clases:', error);
    return [];
  }
}

/**
 * Get subclasses by class
 * @param {string} classId - The ID of the class
 * @returns {array} - The subclasses
 */
export async function getSubClassesByClass(classId) {
  try {
    const subclassesRef = collection(db, 'classes', classId, 'subclasses');
    const querySnapshot = await getDocs(subclassesRef);

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error al obtener el listado de subclases:', error);
    return [];
  }
}

/**
 * Get the App Check token
 * @returns {string} - The App Check token
 */
export async function getAppCheckToken() {
  if (!appCheck) return null;

  try {
    if (!appCheck) {
      await initializeAppCheck();
    }
    const { getToken } = await import('firebase/app-check');
    const appCheckTokenResponse = await getToken(appCheck, false);
    return appCheckTokenResponse.token;
  } catch (err) {
    console.error('Error al obtener el token de App Check:', err);
    return null;
  }
}
